<template>
  <div class="meet" v-if="info">
    <div class="meetMsg">
      <p class="h1">
        会议数据监测
      </p>
      <div class="h2">
        <div class="img-box">
          <img :src="info.img_url" alt="" />
        </div>
        <div class="drac">
          <div>会议名称：{{ info.title }}</div>
          <div>
            上传时间：{{
              moment(Number(info.create_time + "000")).format(
                "YYYY/MM/DD HH:mm:ss"
              )
            }}
          </div>
          <!-- <div>会议类型：</div> -->
          <div>
            会议来源：{{ info.extend_info.play_type == 0 ? "直播" : "采集" }}
          </div>
        </div>
      </div>
    </div>
    <div id="nav" :style="{ top: NavTop }">
      <a @click="goCur('#s1')" :style="{ color: cur == 1 ? '#7EB8B9' : '#333' }"
        >基本内容</a
      >
      <a
        @click="goCur('#s2')"
        :style="{ color: cur == 2 ? '#7EB8B9' : '#333' }"
      >
        基本数据
      </a>
      <a
        @click="goCur('#s3')"
        :style="{ color: cur == 3 ? '#7EB8B9' : '#333' }"
      >
        实名医生
      </a>
    </div>

    <div class="compliance">
      <p>
        相关资料
      </p>
      <a-upload
        name="file"
        @preview="handlePreview"
        :action="uploadImage"
        list-type="picture-card"
        :before-upload="beforeUpload"
        @change="handleChange2"
        :fileList="imgFileList"
      >
        <div v-if="imgFileList.length < 8 && $route.query.qx == 1">
          <a-icon type="plus" />
          <div class="ant-upload-text"></div>
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel2">
        <img
          alt="example"
          style="max-width:450px;max-height:450px"
          :src="previewImage"
        />
      </a-modal>
    </div>
    <div style="height:60px"></div>
    <!-- 基本属性 -->
    <p class="essentialAttribute ">基本属性</p>
    <div class="EAC">
      <div>
        <p class="p1">会议开始时间</p>
        <p>
          {{
            moment(Number(info.start_time + "000")).format(
              "YYYY/MM/DD HH:mm:ss"
            )
          }}
        </p>
      </div>
      <div>
        <p class="p2">会议结束时间</p>
        <p>
          {{
            moment(Number(info.end_time + "000")).format("YYYY/MM/DD HH:mm:ss")
          }}
        </p>
      </div>
      <div>
        <p class="p3">会议时长</p>
        <p>
          {{ info.extend_info.video_time }}
        </p>
      </div>
    </div>
    <div id="s1" style="height:60px"></div>
    <!-- 会议介绍 -->
    <div class="meetContent">
      <p>
        会议介绍
      </p>
      <div class="meetContentDesc">
        {{ info.desc }}
      </div>
      <p>
        会议视频
      </p>
      <video :src="info.extend_info.format_video_url" controls></video>
      <p>
        视频截图
      </p>
      <div class="meetContentImg">
        <div v-for="(item, index) in info.extend_info.images_list" :key="index">
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>
    <div id="s2" style="height:60px"></div>
    <div class="meetData">
      <p>基本数据</p>
      <div class="basicData">
        <!-- 1 -->
        <div>
          <div>
            参会总人数：
          </div>
          <div>
            {{ info.extend_info.live_num + info.extend_info.play_num }}
          </div>
        </div>
        <!-- 2 -->
        <div>
          <div>
            回放参会人数：
          </div>
          <div>
            {{ info.extend_info.play_num }}
          </div>
        </div>
        <!-- 3 -->
        <div>
          <div>
            直播参会人数：
          </div>
          <div>
            {{ info.extend_info.live_num }}
          </div>
        </div>
        <!-- 4 -->
        <div>
          <div>
            已实名认证人数：
          </div>
          <div>
            {{ this.count }}
          </div>
        </div>
      </div>
      <div class="pieBox">
        <div>
          <Echarts id="main1" :options="op1"></Echarts>
        </div>
        <div>
          <Echarts id="main2" :options="op2"></Echarts>
        </div>
      </div>
    </div>
    <div id="s3" style="height:60px"></div>
    <div class="doctorData">
      <div class="doctorDataActionBar">
        <div>实名医生列表</div>

        <a v-if="$route.query.qx == 1" :href="softlinks">医生信息模板下载</a>

        <a-upload
          v-if="$route.query.qx == 1"
          accept=".xls,.xlsx"
          name="file"
          :multiple="true"
          :action="liveImportUser"
          :data="fileData"
          :headers="headers"
          @change="handleChange"
          :fileList="doctorfileList"
        >
          <a-button> <a-icon type="upload" /> 医生信息上传 </a-button>
        </a-upload>
      </div>

      <a-table
        :columns="columns"
        :data-source="data"
        rowKey="id"
        :pagination="{
          total: count,
          current: listParams.page,
          pageSize: listParams.limit,
          showTotal: (total) => `共 ${total} 条`,
        }"
        @change="pageChange"
      >
        <span slot="read_time" slot-scope="text, record">
          <a-input-number
            :min="0"
            @focus="saveOldNum(text)"
            v-model="record.read_time"
            style="width:100px"
            @blur="AchangeVal(record.id, record.read_time)"
          >
          </a-input-number>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  liveShowDetail,
  liveChangePicture,
  liveChangeRead,
  liveImportUser,
  liveDoctor_list,
  uploadImage,
} from "@/apis/index";
import Echarts from "@/components/Echarts/Echarts.vue";
import moment from "moment";

import local from "@/utils/local";
const columns = [
  {
    title: "认证姓名",
    dataIndex: "users.user_realname",
    scopedSlots: { customRender: "user_realname" },
    customRender: (text, record) => {
      return text;
    },
  },

  {
    title: "医 院",
    dataIndex: "users.hospital",
    scopedSlots: { customRender: "hospital" },
    customRender: (text, record) => {
      return text;
    },
  },
  {
    title: "科 室",
    dataIndex: "users.department_name",
    scopedSlots: { customRender: "department_name" },
    customRender: (text, record) => {
      return text;
    },
  },
  {
    title: "职 级",
    dataIndex: "users.professional_name",
    scopedSlots: { customRender: "professional_name" },
    customRender: (text, record) => {
      return text;
    },
  },

  {
    title: "观看时长(秒)",
    dataIndex: "read_time",
    scopedSlots: { customRender: "read_time" },
  },
];
export default {
  data() {
    return {
      liveImportUser,
      columns,
      uploadImage,
      info: "",
      moment,
      NavTop: "",

      cur: 1,
      doctorfileList: [],

      //视频截图
      imgFileList: [],
      previewVisible: false,
      previewImage: "",
      imageList: [],

      op1: {},
      op2: {},

      listParams: {
        id: this.$route.query.id,
        page: 1,
        limit: 10,
      },
      count: 0,
      data: [],
      // 上传
      fileData: {
        id: this.$route.query.id,
      },
      headers: {
        "XX-Token": local.get("adminToken"),
      },

      oldNum: 0,

      XGFlag: false,

      softlinks: "",
    };
  },
  components: {
    Echarts,
  },

  watch: {
    imageList(val) {
      let arr = [];
      if (this.imageList) {
        this.imageList.forEach((item) => {
          arr.push(item.uid);
        });

        if (this.XGFlag) {
          liveChangePicture({
            id: this.$route.query.id,
            pictures: arr.toString(),
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success("修改成功");
            }
          });
        }
      }
    },
  },

  created() {
    liveShowDetail({ id: this.$route.query.id }).then((res) => {
      if (res.code == 1) {
        this.info = res.data.info;

        if (this.info.extend_info.pictures_list) {
          this.info.extend_info.pictures_list.forEach((item) => {
            this.imgFileList.push({
              uid: item.id,
              name: "image.png",
              url: item.url,
              status: "done",
            });
            this.imageList.push({
              uid: item.id,
              name: "image.png",
              url: item.url,
              status: "done",
            });
          });
        }
        this.softlinks = res.data.down_url;
        this.count = res.data.doctor_data.count;
        this.data = res.data.doctor_data.list;
        this.setOp1(res.data.professional_list);
        this.setOp2(res.data.time_list);
      }
    });
  },

  mounted() {
    window.addEventListener("scroll", this.getScroll, true);
  },

  destroyed() {
    window.removeEventListener("scroll", this.getScroll, true);
  },
  methods: {
    saveOldNum(num) {
      this.oldNum = num;
    },
    AchangeVal(id, change_value) {
      if (this.oldNum == change_value) {
        return false;
      } else {
        liveChangeRead({
          id,
          change_value,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            liveShowDetail({ id: this.$route.query.id }).then((res) => {
              if (res.code == 1) {
                this.info = res.data.info;
                this.setOp2(res.data.time_list);
              }
            });
          }
        });
      }
    },

    handleChange(info) {
      if (info.file.status === "uploading") {
        this.doctorfileList = info.fileList;
        return;
      }
      if (info.file.status === "done") {
        if (info.file.response.code == 1) {
          this.$message.success(info.file.response.msg);
          this.doctorfileList = [];
          liveShowDetail({ id: this.$route.query.id }).then((res) => {
            if (res.code == 1) {
              this.info = res.data.info;
              this.count = res.data.doctor_data.count;
              this.data = res.data.doctor_data.list;
              this.setOp1(res.data.professional_list);
              this.setOp2(res.data.time_list);
            }
          });
        } else {
          this.$message.error(info.file.response.msg);
          this.doctorfileList = [];
        }
      }
    },

    setOp1(professional_list) {
      var TheTitleList = [];
      var redNum = [];
      professional_list.forEach((item, index) => {
        TheTitleList.push(item.name);
        redNum.push({
          value: item.user_count,
          name: item.name,
        });
      });
      this.op1 = {
        title: {
          text:
            TheTitleList.length == 0
              ? "参会医生职称分布(暂无数据)"
              : "参会医生职称分布：",
          left: 0,
          top: 30,
          textStyle: {
            color: "#999999",
          },
        },
        color: [
          "#1842DE",
          "#4E8CCE",
          "#A3B7FF",
          "#9ED1E9",
          "#1989DD",
          "#CAE6F9",
          "#4E8CCE",
          "#1B96DB",
          "#6FBEE4",
          "#6E88B6",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 90,
          data: TheTitleList,
        },
        series: [
          {
            name: "职称占比",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["45%", "60%"],
            avoidLabelOverlap: false,
            data: redNum,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    setOp2(time_list) {
      var TheTitleList = [];
      var redNum = [];
      time_list.forEach((item, index) => {
        TheTitleList.push(item.title);
        redNum.push({
          value: item.number,
          name: item.title,
        });
      });

      this.op2 = {
        title: {
          text:
            TheTitleList.length == 0
              ? "参会医生观看时间分布(暂无数据)"
              : "参会医生观看时间分布：",
          left: 0,
          top: 30,
          textStyle: {
            color: "#999999",
          },
        },
        color: [
          "#4BAABD",
          "#0089A9",
          "#E3F6F6",
          "#67CDCE",
          "#2C9B9B",
          "#68D5E0",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 90,
          data: TheTitleList,
        },
        series: [
          {
            name: "观看时间比例",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["45%", "60%"],
            data: redNum,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },

    goCur(i) {
      document.querySelector(i).scrollIntoView({});
      if (i == "#s1") {
        this.cur = 1;
      } else if (i == "#s2") {
        this.cur = 2;
      } else {
        this.cur = 3;
      }
    },
    getScroll(e) {
      var cur1 = document.querySelector("#s1");
      var cur2 = document.querySelector("#s2");
      var cur3 = document.querySelector("#s3");
      if (e.target.scrollTop > 370) {
        this.NavTop = e.target.scrollTop - 370 + "px";
      } else {
        this.NavTop = 0;
      }

      if (cur1 && e.target.scrollTop < cur2.offsetTop - 100) {
        this.cur = 1;
      } else if (
        cur1 &&
        e.target.scrollTop > cur2.offsetTop - 100 &&
        e.target.scrollTop < cur3.offsetTop - 200
      ) {
        this.cur = 2;
      } else {
        this.cur = 3;
      }
    },

    // 视频截图
    handleChange2(info) {
      this.XGFlag = true;
      let { fileList } = info;
      this.imgFileList = [...fileList];
      if (info.file.status === "done") {
        this.imageList.push({
          uid: info.file.response.data.id,
          name: "image.png",
          url: info.file.response.data.url,
          status: "done",
        });
      }
      if (info.file.status === "removed") {
        if (info.fileList.length == 0) {
          this.imgFileList = this.imageList;
          this.$message.error("至少保留一张合规资料");
          return;
        }
        if (this.$route.query.qx != 1) {
          this.imgFileList = this.imageList;
          this.$message.error("您没有权限");
          return;
        } else {
          if (typeof info.file.uid == "string") {
            this.imageList.filter((item, index) => {
              if (item.uid == info.file.response.data.id) {
                this.imageList.splice(index, 1);
              }
            });
          } else {
            this.imageList.filter((item, index) => {
              if (item.uid == info.file.uid) {
                this.imageList.splice(index, 1);
              }
            });
          }
        }
      }
    },
    handleCancel2() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.response.data.url;
      this.previewVisible = true;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },

    pageChange(a) {
      this.listParams.page = a.current;
      liveDoctor_list(this.listParams).then((res) => {
        if (res.code == 1) {
          this.data = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scope>
.meet {
  color: #333;
  padding-top: 20px;
  .meetMsg {
    background: #fff;
    padding: 20px;

    .h1 {
      font-size: 26px;
      font-weight: bold;
    }
    .h2 {
      display: flex;
      .img-box {
        width: 380px;
        height: 230px;
        display: flex;

        img {
          max-width: 380px;
          max-height: 230px;
        }
      }
      .drac {
        padding: 20px 40px;
        height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 20px;
      }
    }
  }
  #nav {
    display: flex;
    padding: 15px 20px;
    background: #fff;
    position: relative;
    z-index: 100;
    a {
      font-weight: bold;
      font-size: 26px;
      margin-right: 26px;
      color: #333;
    }
  }

  .compliance {
    background: #fff;
    margin-top: 60px;
    padding: 20px 30px;
    border-radius: 12px;
    & > p {
      height: 40px;
      line-height: 40px;
      font-size: 22px;
    }
    .ant-upload-list-picture-card-container {
      width: 350px !important;
      height: 206px !important;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      width: 350px !important;
      height: 206px !important;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
      width: auto;
    }
    img {
      margin: 0 auto !important;
    }
    .ant-upload.ant-upload-select-picture-card {
      margin-top: 50px;
    }
  }
  .essentialAttribute {
    font-size: 22px;
    text-indent: 1em;
  }
  .EAC {
    width: 100%;
    display: flex;
    & > div {
      border-radius: 12px;
      margin-right: 20px;
      width: 420px;
      height: 180px;
      box-sizing: border-box;
      background: #fff;
      padding: 30px;
      font-size: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      & > p:first-child {
        color: #888;
        font-size: 20px;
        position: relative;
        text-indent: 25px;
      }
      .p1::before {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-image: linear-gradient(45deg, #a5f2fc, #5bb1e4);
        position: absolute;
        top: 5px;
      }
      .p2::before {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-image: linear-gradient(45deg, #ffd5a0, #f69951);
        position: absolute;
        top: 5px;
      }
      .p3::before {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-image: linear-gradient(45deg, #feb4c5, #e85674);
        position: absolute;
        top: 5px;
      }
    }
  }

  .meetContent {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    .meetContentDesc {
      background: #f9f9f9;
      color: #757575;
      padding: 20px;
      border-radius: 12px;
    }
    & > p {
      font-size: 22px;
      line-height: 60px;
      margin: 0;
    }
    video {
      width: 1130px;
      height: 500px;
      border: 1px solid #999;
      border-radius: 12px;
    }
    .meetContentImg {
      display: flex;

      div {
        width: 350px;
        height: 206px;
        margin-right: 30px;
        img {
          max-width: auto;
          max-height: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .meetData {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    & > p {
      font-size: 22px;
    }
    .basicData {
      display: flex;
      & > div {
        width: 170px;
        height: 239px;
        box-sizing: border-box;
        margin-right: 90px;
        background-image: url("../../../assets/img/LivePlayback/canhui.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 128px 0 0 24px;
        color: #fff;
        & > div:first-child {
          font-size: 16px;
        }
        & > div:last-child {
          font-size: 26px;
          line-height: 80px;
        }
      }
      & > div:nth-child(2) {
        background-image: url("../../../assets/img/LivePlayback/huifangcanhui.png");
      }
      & > div:nth-child(3) {
        background-image: url("../../../assets/img/LivePlayback/zhibocanhui.png");
      }
      & > div:nth-child(4) {
        background-image: url("../../../assets/img/LivePlayback/shimingrenzhen.png");
      }
    }

    .pieBox {
      display: flex;
      margin-top: 20px;
      & > div {
        width: 630px;
        height: 369px;
        margin-right: 60px;
        #main1 {
          width: 580px;
          height: 369px;
        }
        #main2 {
          width: 580px;
          height: 369px;
        }
      }
    }
  }
  .doctorData {
    .doctorDataActionBar {
      display: flex;
      align-items: center;
      height: 50px;
      & > div {
        font-size: 22px;
        text-indent: 20px;
        margin-right: 30px;
        line-height: 50px;
      }
      & > a {
        color: #595959;
        margin-right: 30px;
        border: 1px solid #d8d9d9;
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
      }
      & > a:hover {
        color: #7eb8b9;
        border: 1px solid #7eb8b9;
      }
    }
  }
}
</style>
